import React, { useState } from 'react';
import Flex, { FlexItem } from 'styled-flex-component';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import { P } from '../components/common/P.style';
import { Img } from '../components/common/Img.style';

import WeeklySchedule from '../components/WeeklySchedule';
import ClosedDayLabel from '../components/ClosedDayLabel';
import AccessInfoArea from '../components/AccessInfoArea';
import Modal from '../components/Modal';

import us1lg from '../assets/img/us-1-lg.png';

const ModalBody = () => (
  <div
    css={`
      width: 90%;
    `}
  >
    <P>
      当院の理念は「安心」です。
      <br />
      今回はなぜ当院の理念として「安心」を据えたのかというお話をさせていただきたいと思います。
      <br />
    </P>
    <P>
      僕がまだ研修医のころ、ある男性の入院患者さんを担当させていただきました。
      <br />
      その方は胃癌の末期で、すでに治癒は期待できない状態でした。
      <br />
      当然、徐々に状態は悪くなっていくわけで、いいお話を持っていくことはなかなかできません。
      <br />
      そのかわり、僕は毎日ベッドの横に椅子を出し、20分くらいお話を聞くことにしました。
      <br />
      そうした日がどれほど続いたでしょうか。
      <br />
      ある時、その患者さんがポツリとこうおっしゃいました。
      <br />
      「俺は先生がいるから安心して死ねるよ」
    </P>
    <P>
      僕はその患者さんに、「医療機関を受診される方がもっとも求めているものは『安心』なんだ」ということを教わったように思います。
      <br />
      もちろん、医療の技術、知識を常に磨いていくことは絶対に必要なことです。
      <br />
      しかしそれも結局のところ、「安心」のため、と考えることができるのではないでしょうか。
    </P>
    <P>
      当院は、患者さんの「安心」だけでなく、スタッフの「安心」も追及しています。
      <br />
      安心して働けなければ、スタッフも受診者の皆さんに安心を与えることができないと考えるからです。
    </P>
  </div>
);

const ModalHead = () => (
  <div
    css={`
      width: 90%;
    `}
  >
    <Flex
      column
      css={`
        margin: 30px 0;
      `}
    >
      <span
        css={`
          font-size: 16px;
          color: #3b4043;
        `}
      >
        当院の理念「安心」の由来
      </span>
    </Flex>
    <hr />
  </div>
);

const About = () => {
  const { size } = useTrackedState();
  const [open, setOpen] = useState(false);
  return (
    <SubLayout topTitle="当院について">
      <Img src={us1lg} />
      <P>
        当院は企業で働く方々を支えるため、1985年に渋谷で開設されたクリニックです。
        受診者が医療機関に求めているものは「安心」と考え、それを基準に医師、スタッフが一丸となって取り組んでいます。
      </P>
      <P
        css={`
          text-align: center;
          cursor: pointer;
          font-style: italic;
        `}
        onClick={() => setOpen(true)}
      >
        （当院の理念「安心」の由来はこちら）
      </P>
      <Modal
        width={(size === 'sm' && '98%') || '60%'}
        header={<ModalHead />}
        body={<ModalBody />}
        open={open}
        onClose={() => setOpen(false)}
      />
      <P>
        その上で、「自分が受けたい医療を提供する」ということをモットーにしております。
        さまざまな迅速検査機器をそろえているのも、エコー、胃カメラ、大腸カメラを丁寧に、しっかり時間をとって施行させていただいているのも、その表れです。
        お困りのことがあれば、お気軽にご相談ください。
      </P>
      <P>
        朝から夜８時まで、「渋谷で働く方々の健康を守る」との設立時の目的のままに、
        皆様の「安心」を愚直に追求しています。
      </P>
      <Flex center column>
        <FlexItem>
          <WeeklySchedule size={size} headerBackgroundColor="#efefef" />
          <FlexItem>
            <Flex justifyEnd={size !== 'sm'} wrap="true">
              {/* <ClosedDayLabel /> */}
            </Flex>
          </FlexItem>
        </FlexItem>
      </Flex>
      <AccessInfoArea />
      <P>
        <strong>特定商取引法に基づく表記</strong>
        <br />
        事業者名：医療法人社団創友会ヒラハタクリニック
        <br />
        代表者氏名：平畑 光一
        <br />
        所在地：〒150-0002　東京都渋谷区渋谷1-24-6マトリクス・ツービル9F
        <br />
        電話番号：03-3400-3288
        <br />
        URL：https://www.hirahata-clinic.or.jp/
        <br />
        販売価格：診療費、およびシステム利用料2000円
        <br />
        支払方法：クレジット決済
        <br />
        返品・交換・キャンセル等：役務の特性上返品は不可
        <br />
      </P>
    </SubLayout>
  );
};

export default About;
